export const TOKEN_KEY = 'token';
export const EXPIRED_KEY = 'streamExpired';
const url = new URL(location.href);
const search = new URLSearchParams(window.location.search);

const queryToken = url.searchParams.get(TOKEN_KEY);

if (!url.host.includes('localhost')) {
  url.searchParams.delete(TOKEN_KEY);
  window.history.pushState({}, document.title, url.toString());
}

const getAuthToken = (): string => {
  return queryToken;
};

export const forceProdApi = search.get('prodApi') === 'true';

export const token = getAuthToken();

export const isSocketsDebugEnabled = search.get('socketDebug') === 'true';
export const isDebugEnabled = search.has('debug');

export const isDemo = search.get('demo') === 'true' || window?.location?.host?.startsWith('demo');
export const isAdmin = isDemo && search.get('admin') === 'true';

export const enableLogging = search.get('logging') === 'true';

export const queryTheme = search.get('theme');
