export const setLocalStorageData = (itemName: string, data: any | null): void => {
  if (!lsTest()) return;
  const storageData = getLocalStorageData(itemName) || {};

  if (typeof data !== 'object' || Array.isArray(data)) {
    data = { $value: data };
  }

  localStorage.setItem(itemName, JSON.stringify({ ...storageData, ...data }));
};

export const setLocalStorageSimpleData = (itemName: string, data: any | null): void => {
  if (!lsTest()) return;

  localStorage.setItem(itemName, JSON.stringify({ $value: data }));
};

export const getLocalStorageData = (itemName: string): any | null => {
  if (!lsTest()) return null;
  const data = localStorage.getItem(itemName);
  const result = JSON.parse(data);

  // $value is for primitive values to store as object
  if (result && '$value' in result) {
    return result.$value;
  }

  return result;
};

export const removeLocalStorageData = (itemName: string): void => {
  if (!lsTest()) return null;
  localStorage.removeItem(itemName);
};

export const lsTest = (): boolean => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    console.log('localStorage unavailable');
    return false;
  }
};
