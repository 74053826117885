import jwt_decode from 'jwt-decode';
import { token, isDemo } from '@/api/helpers/urlParams';
import { Role } from '@/helpers/roleHelper';

export interface TokenData {
  role: string;
  id_user: number;
  id_operator: number;
  exp: number;
  language?: string;
}

export const validateToken = (token?: string): string => {
  if (getMsToTokenExpire(token)) {
    return token;
  }

  return null;
};

export const parsedToken = (token?: string): TokenData => {
  return jwt_decode(token);
};

export const getMsToTokenExpire = (targetToken: string = token): number => {
  try {
    const now = new Date().getTime();
    const { exp }: TokenData = parsedToken(targetToken);

    return Math.max(exp * 1e3 - now, 0);
  } catch (err) {
    return 0;
  }
};

export const isAuthorized = (targetToken: string = token): boolean => {
  try {
    const { role } = parsedToken(targetToken);

    return [Role.User, Role.Anonimus].includes(role) && !!Math.max(getMsToTokenExpire(targetToken) - 5000, 0);
  } catch (error) {
    return false;
  }
};

export const getUserId = (targetToken: string = token): number => {
  if (!isAuthorized(targetToken)) return;

  try {
    const { id_user }: TokenData = parsedToken(targetToken);

    return id_user;
  } catch (e) {
    return undefined;
  }
};

export const getOperatorId = (): number => {
  try {
    const { id_operator } = parsedToken(token);

    return id_operator;
  } catch (e) {
    return undefined;
  }
};

export const getUserRole = (targetToken: string = token): Role => {
  if (isDemo) return Role.User;

  try {
    const { role }: TokenData = parsedToken(targetToken);

    return role as Role;
  } catch (e) {
    return undefined;
  }
};

export const isAnonymousRole = (targetToken: string = token): boolean => {
  try {
    return getUserRole(targetToken) === Role.Anonimus;
  } catch (e) {
    return false;
  }
};

export const isValidToken = (targetToken: string = token) => {
  if (isDemo) return true;

  try {
    const { role } = parsedToken(targetToken);

    return [Role.Anonimus, Role.User].includes(role) && !!Math.max(getMsToTokenExpire(targetToken) - 5000, 0);
  } catch (e) {
    return false;
  }
};

export const getTokenLanguage = (targetToken: string = token): string => {
  if (isDemo) return 'en';

  try {
    const { language } = parsedToken(targetToken);

    if (typeof language === 'string') {
      return language;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
