

























import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { isDemo, token, isDebugEnabled } from '@/api/helpers/urlParams';

import BottomNavigation from '@/components/bottom-navigation/BottomNavigation.vue';
import BottomLogoLink from '@/components/bottom-logo-link/BottomLogoLink.vue';
import FaqModal from '@/modules/faq/FaqModal.vue';

import { hexToRGBNumber } from '@/helpers/colorHelpers';
import { isValidToken, isAnonymousRole, getTokenLanguage } from '@/helpers/tokenHelpers';
import { DisabledWidgetStatus } from '@/helpers/disabledWidgetStatusHelpers';
import { isAnonymousSessionExpired } from '@/helpers/anonymousSessionHelpers';
import { defaultLocale } from '@/helpers/localeHelpers';
import { postMessage } from '@/helpers/postMessageHelpers';

const CloseWidgetHeader = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/components/header/CloseWidgetHeaderRow.vue');
const OverlayDisabledWidget = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/components/overlay/OverlayDisabledWidget.vue');
const BlockOverlay = (): Promise<any> => import(/* webpackMode: "lazy" */ '@/components/common/BlockOverlay.vue');
const DebugLink = (): Promise<any> => import(/* webpackMode: "lazy" */ '@/components/base/BaseDebugLink.vue');

const isDev = process.env.NODE_ENV === 'development';
const locale = getTokenLanguage() || defaultLocale;

export default {
  name: 'App',
  data(): any {
    return {
      initComplete: false,
      noAuthToken: !isValidToken(token),
      isDebugEnabled: (isDebugEnabled || isDev) && !isDemo,
      isDemo
    };
  },
  components: {
    FaqModal,
    BottomLogoLink,
    BottomNavigation,

    DebugLink,
    BlockOverlay,
    CloseWidgetHeader,
    OverlayDisabledWidget
  },
  computed: {
    ...mapState('layout', ['operatorHomePageURL', 'disabledWidgetStatus', 'themeColors', 'userLogin', 'lang']),
    ...mapGetters('layout', ['activeTheme']),

    colors(): { [key: string]: string } {
      const colors = Object.entries(this.themeColors).reduce((prev, [key, value]) => {
        prev.push([`--${key}`, value]);

        if (String(value).startsWith('#')) {
          prev.push([`--${key}-rgb`, hexToRGBNumber(String(value))]);
        }

        return prev;
      }, []);

      return Object.fromEntries(colors);
    },
    backgroundColor(): string {
      return this.themeColors?.['theme-background-color'] || '#131313';
    },
    showCloseHeader(): boolean {
      return !this.demo && !this.$screen.sm && this.operatorHomePageURL;
    },
    isWidgetDisabled(): boolean {
      return this.initComplete && !!this.disabledWidgetStatus && !isDemo;
    },
    isAnonymous(): boolean {
      return isAnonymousRole();
    }
  },
  watch: {
    activeTheme: {
      handler(val: string): void {
        document.querySelector('html').dataset.themeNew = val;
        document.body.dataset.themeNew = val;
      },
      immediate: true
    },
    backgroundColor: {
      handler(color: string): void {
        document.body.style.backgroundColor = color;
      },
      immediate: true
    }
  },
  created(): void {
    if (!isDemo) {
      const noAuthToken = !isValidToken(token);
      if (noAuthToken) {
        this.setDisabledWidgetStatus(DisabledWidgetStatus.NO_AUTH_TOKEN);
      }

      if (isAnonymousRole() && isAnonymousSessionExpired()) {
        this.setDisabledWidgetStatus(DisabledWidgetStatus.ANONYMOUS_SESSION_EXPIRED);
      }
    }

    this.initWidget();
  },
  destroyed(): void {
    window.removeEventListener('message', this.handlePostMessage);
  },
  methods: {
    ...mapActions('layout', [
      'getUserInfo',
      'getOperatorTheme',
      'setActiveLanguage',
      'loadTranslation',
      'getConfiguration'
    ]),
    ...mapMutations('layout', ['setDisabledWidgetStatus', 'setTheme', 'setUserInfo']),

    handlePostMessage(event: MessageEvent): void {
      const {
        data: { type, value }
      } = event;

      if (type === 'theme') {
        this.setTheme({ value });
      }

      if (type === 'change-colors') {
        this.setTheme({ colors: value });
      }

      if (type === 'redirect' && this.$route.path !== value) {
        this.$router.push({ path: value, query: { ...this.$route.query } }).catch((error: Error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.error(error);
          }
        });
      }

      if (type === 'ui_init_watch') {
        document.body.classList.add('initial-height');
        this.runWatcher = true;
      }

      if (!this.runWatcher) return;

      if (type === 'ui_hide_nav' && !this.hideBottomNav) {
        this.hideBottomNav = true;
      }

      if (type === 'ui_show_nav' && this.hideBottomNav) {
        this.hideBottomNav = false;
      }

      if (type === 'ui_redirect' && this.allRoutes.includes(value)) {
        this.$router.push({ path: value, query: { ...this.$route.query } }).catch((error: Error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.error(error);
          }
        });
      }
    },
    initFrameListeners(): void {
      window.addEventListener('message', this.handlePostMessage);

      postMessage({ type: 'init-admin' });
    },
    async initWidget(): Promise<void> {
      try {
        await this.loadInitialData();
        this.initFrameListeners();
      } catch (e) {
        this.setDisabledWidgetStatus(DisabledWidgetStatus.DEFAULT);
      } finally {
        await this.initTranslations();
        await this.hideGlobalPreloader();
      }
    },
    async loadInitialData(): Promise<void> {
      try {
        const requests = [];
        const secondaryRequests = [];

        if (token || isDemo) {
          requests.push(this.loadTranslation(locale));
          secondaryRequests.push(this.getOperatorTheme(), this.getConfiguration());

          if (this.isAnonymous) {
            this.setUserInfo({});
          } else {
            requests.push(this.getUserInfo());
          }
        }

        await Promise.all(requests);
        await Promise.allSettled(secondaryRequests);
      } catch (e) {
        console.error('loadInitialData: ', e);
        throw Error(e);
      }
    },
    async initTranslations(): Promise<void> {
      if ((!this.lang || this.lang === locale) && token) return;

      await this.setActiveLanguage(this.lang);
    },
    async hideGlobalPreloader(): Promise<void> {
      const getLoader = () => document.getElementById('loader');
      const APP_LOADER = getLoader();

      await this.$nextTick();

      if (APP_LOADER) {
        APP_LOADER?.classList.add('global-loader--complete');
        setTimeout(() => {
          this.initComplete = true;
          window.mountDate = Date.now();

          setTimeout(() => {
            const APP_LOADER = getLoader();
            APP_LOADER.remove();
          }, 500);
        }, 200);
      }
    }
  }
};
