export const currentDomain = `${location.protocol}//${location.hostname}:${location.port}`;

export const parentUrl = (parent !== window ? document.referrer : '').toLowerCase();

export let parentDomain = '';

try {
  const url = new URL(parentUrl);
  parentDomain = url.hostname;
} catch (e) {
  // ignore
}

export const parentIsFS = ['fairspin'].some((v) => parentDomain.includes(v));

export const parentIsDev =
  ['operator.dev'].some((v) => parentDomain.includes(v)) || window.location.hostname.includes('localhost');

const origin = window?.location?.origin;
const isAppLink = origin?.includes('copystake');
export const API_CLIENT_URL = isAppLink ? origin.replace('copystake', 'api') : process.env.VUE_APP_API_URL;
