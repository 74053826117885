












































import ClickOutside from 'vue-click-outside';
import { mapState } from 'vuex';
import { setLocalStorageSimpleData, getLocalStorageData } from '@/helpers/localStorageHelpers';

const STORAGE_KEY = 'copy-stake-history';

export default {
  name: 'StreamConnectInput',
  directives: {
    ClickOutside
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    },
    success: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    connection: {
      type: String,
      default: ''
    },
    isStreamer: {
      type: Boolean,
      default: false
    }
  },
  data(): { value: string; list: string[]; showHistory: boolean } {
    return {
      list: [],
      value: '',
      showHistory: false
    };
  },
  computed: {
    ...mapState('layout', ['walletHash']),

    history: {
      get(): string[] {
        if (!this.connection?.length && !this.walletId?.length) return this.list;

        return this.list.filter((h: string): boolean => {
          if (h === this.connection) return false;

          if (this.walletId?.length) {
            return h?.includes(this.walletId);
          }

          return true;
        });
      },
      set(list: string[]): void {
        this.list = [...new Set(list)].slice(0, 3);
        setLocalStorageSimpleData(this.storageKey, this.list);
      }
    },
    walletId: {
      get(): string {
        return this.value;
      },
      set(v: string): void {
        if (this.error) {
          this.clearErrors();
        }

        this.value = v?.toLocaleLowerCase();
      }
    },

    errorKey(): string {
      if (this.errorMessage) return this.errorMessage;
      if (!this.value) return 'wallet-not-provided';
      if (this.walletId === this.walletHash) return 'wallet-error-own';
      if (this.walletId === this.connection) return 'wallet-same-error';
      if (this.validationError) return 'wallet-validation-error';

      return 'wallet-error';
    },
    hasErrors(): boolean {
      return this.error || this.validationError;
    },
    hasWallet(): boolean {
      return this.walletId?.length;
    },
    storageKey(): string {
      return `${STORAGE_KEY}-${this.walletHash}`;
    },
    buttonLabel(): string {
      return this.$t(`stream.${this.isSameConnection ? 'connected' : 'connect'}`);
    },
    inputModifiers(): Record<string, boolean> {
      return {
        'stream-connect-input__field--error': this.hasErrors
      };
    },
    buttonModifiers(): Record<string, boolean> {
      return {
        'stream-connect-input__btn--success': this.isSameConnection,
        'stream-connect-input__btn--loading': this.loading
      };
    },
    validationError(): boolean {
      if (!this.walletId?.length) return false;

      const lettersAndNumbers = /^[\da-zA-Z-]*$/i;

      return !lettersAndNumbers.test(this.walletId);
    },
    isSameConnection(): boolean {
      if (!this.walletId || !this.connection) return false;
      return this.walletId === this.connection;
    }
  },
  watch: {
    success: 'handleSuccess',
    connection: 'handleConnection',
    walletHash: {
      handler: 'loadHistory',
      immediate: true
    }
  },
  methods: {
    handleClick(): void {
      if (!this.list.length) return;

      this.showHistory = true;
    },
    clearErrors(): void {
      this.$emit('update:error', false);
      this.$emit('update:error-message', null);
    },
    loadHistory(wallet: string): void {
      if (!wallet) return;

      this.list = getLocalStorageData(this.storageKey) || [];
    },
    handleSuccess(isSuccess: boolean): void {
      if (!isSuccess) return;
      this.$refs?.input.blur();
      setTimeout(() => {
        this.walletId = this.connection;
        if (!this.isStreamer) {
          this.history = [this.walletId, ...this.list];
        }
        this.$emit('update:success', false);
      }, 2000);
    },
    handleConnect(): void {
      if (!this.hasWallet) {
        this.$emit('update:error', true);
        this.$refs?.input.focus();
        return;
      }
      this.showHistory = false;
      if (this.hasErrors || this.success || this.loading || this.isSameConnection) return;

      this.$emit('connect', this.walletId);
    },
    handleConnection(connection: string): void {
      if (this.walletId === connection) return;

      this.walletId = connection;
    },
    selectFromHistory(hash: string): void {
      this.showHistory = false;
      this.$nextTick(() => {
        this.walletId = hash;
        this.handleConnect();
      });
    },
    removeFromHistory(hash: string): void {
      this.history = this.list.filter((h: string): boolean => h !== hash);
    },
    handleOutsideClick(): void {
      this.showHistory = false;
      if (this.connection && this.connection !== this.walletId) {
        this.$nextTick(() => {
          if (this.hasErrors) {
            this.clearErrors();
          }
          this.walletId = this.connection;
        });
      }
    }
  }
};
