import startCase from 'lodash/startCase';

export const shortenString = (v: string, startLength: number = 8, endLength: number = 8): string => {
  if (!v || v.length <= endLength + endLength) {
    return v;
  }

  return `${v.slice(0, startLength)}…${v.slice(-endLength)}`;
};

export const formatTXType = (type: string): string => {
  if (typeof type !== 'string') return type || '';

  return startCase(type.toLowerCase());
};
