import { getCurrentInstance, computed } from '@vue/composition-api';
import type { VueScreenObject } from 'vue-screen';

const useScreen = () => {
  const currentInstance = getCurrentInstance();

  const screen = computed<VueScreenObject>(() => currentInstance.proxy.$screen);

  const isMobile = computed<boolean>(() => !screen.value.sm);

  const isDesktop = computed<boolean>(() => screen.value.lg);

  return {
    screen,
    isMobile,
    isDesktop
  };
};

export default useScreen;
