



















































import BaseDropdown from '@/components/base/BaseDropdown.vue';
export default {
  name: 'StreamConnectSelect',
  components: { BaseDropdown },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: (): any[] => []
    }
  },
  data(): { hideFollowers: boolean } {
    return {
      hideFollowers: true
    };
  }
};
