import { http } from './Connect';
import { AxiosResponse } from 'axios';
import { UserInfo, WidgetThemeResponse } from './schema';
import { LocaleMessages } from 'vue-i18n';

/**
 * Get user role.
 * @return Promise with user role.
 */
export async function getUserInfo(): Promise<UserInfo> {
  return http.get(`/api/v1/copystake/user/info`).then(({ data }: AxiosResponse): UserInfo => data);
}

export async function updateUserNickname(name: string): Promise<void> {
  return http.patch(`/api/v1/copystake/user/nickname?name=${name}`);
}

/**
 * Get widget languages.
 * @return Promise with languages.
 */
export async function getWidgetLanguages(): Promise<string[]> {
  return http.get(`/api/v1/copystake/user/languages`).then(({ data }: AxiosResponse): string[] => data);
}

/**
 * Set user language.
 * * @param language
 */
export async function setUserLanguage(language: string): Promise<unknown> {
  return http.put(`/api/v1/copystake/user/language`, {}, { params: { language } });
}

/**
 * Get translation for locale.
 * @param locale
 * @return Promise with translations in i18n.
 */
export async function getTranslation(locale: string): Promise<LocaleMessages> {
  return http
    .get(`/api/v1/copystake/translations`, { params: { locale } })
    .then(({ data }: AxiosResponse): LocaleMessages => data);
}

export function getOperatorTheme(): Promise<WidgetThemeResponse> {
  return http.get('/api/v1/copystake/user/widget-theme').then(({ data }: AxiosResponse): WidgetThemeResponse => data);
}
