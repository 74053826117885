







import LogoTrueplay from '@/assets/images/logos/logo-trueplay.svg?inline';

export default {
  name: 'BottomLogo',
  components: {
    LogoTrueplay
  }
};
