export function getLongHex(hex: string): string {
  if (hex?.length === 4) {
    return `#${hex[1].repeat(2)}${hex[1].repeat(2)}${hex[3].repeat(2)}`;
  }

  return hex;
}

export function hexToRGB(hex: string, alpha: number = undefined): string {
  const longHex = getLongHex(hex);
  const r = parseInt(longHex.slice(1, 3), 16);
  const g = parseInt(longHex.slice(3, 5), 16);
  const b = parseInt(longHex.slice(5, 7), 16);

  if (!isNaN(alpha)) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export function hexToRGBNumber(hex: string): string {
  const longHex = getLongHex(hex);
  const r = parseInt(longHex.slice(1, 3), 16);
  const g = parseInt(longHex.slice(3, 5), 16);
  const b = parseInt(longHex.slice(5, 7), 16);

  return [r, g, b].join(', ');
}
