import {
  ELobbyFilterType,
  ELobbySection,
  ELobbySortType,
  EStreamActionType,
  EStreamCloseReason,
  EStreamSubscriptionState,
  ILobbyRecord,
  TLobbyRequestParams,
  TLobbyResponseItem,
  TLobbyResponsePayload,
  TStreamGameAction,
  TStreamSubscription
} from '@/api/schema';
import { DateTime } from 'luxon';
import Vue from 'vue';

import {
  getLocalStorageData,
  removeLocalStorageData,
  setLocalStorageData,
  setLocalStorageSimpleData
} from '@/helpers/localStorageHelpers';
import numeralSpaces from '@/filters/numeral-spaces';
import { formatTXType } from '@/helpers/formatString';

import orderBy from 'lodash/orderBy';
import memoize from 'lodash/memoize';

import type { NavigationGuard, Route } from 'vue-router';
import { EXPIRED_KEY, isDemo } from '@/api/helpers/urlParams';

export type TCopyStakeValidator = (v: Route['params']) => boolean;

export type TModalPayload = {
  icon?: string;
  text?: string;
  controls?: { cancel?: any; submit?: any };
};
export enum EActionOwner {
  player = 'player',
  follower = 'follower'
}

export const StreamEventBus = new Vue();

export const STREAM_MESSAGE = {
  [EStreamCloseReason.USER_LOST_CONNECTION]: 'connection-lost',
  [EStreamCloseReason.STREAMER_OFFLINE]: 'stream-is-over',
  [EStreamCloseReason.INSUFFICIENT_FUNDS]: 'balance-is-over-bet-replication',
  [EStreamCloseReason.INSUFFICIENT_FUNDS_START]: 'balance-is-over-to-start',
  [EStreamCloseReason.INSUFFICIENT_SPINS]: 'spins-is-over',
  [EStreamCloseReason.CHANGED_BET_RULE]: 'change-bet-rule',
  [EStreamCloseReason.OFF_BET_INACTIVE]: 'off-bet-inactive',
  [EStreamCloseReason.CHANGED_GAME_TYPE]: 'change-game',
  [EStreamCloseReason.UNSUPPORTED_GAME_TYPE]: 'unsupported-game-type',
  [EStreamCloseReason.TURN_OFF]: 'off-bet-replication',
  [EStreamCloseReason.SESSION_EXPIRED]: 'session-expired',
  CONFIRMATION: 'confirmation',
  NEED_AUTH: 'need-auth'
};

export const STREAM_NOTIFICATION_ICON = {
  [EStreamCloseReason.USER_LOST_CONNECTION]: 'ConnectionLostIcon',
  [EStreamCloseReason.INSUFFICIENT_FUNDS]: 'DepositWalletIcon',
  [EStreamCloseReason.STREAMER_OFFLINE]: 'NoStreamIcon',
  [EStreamCloseReason.SESSION_EXPIRED]: 'NoStreamIcon',
  [EStreamCloseReason.INSUFFICIENT_FUNDS_START]: 'ExclamationShieldIcon',
  NEED_AUTH: 'ExclamationShieldIcon'
};

export const NOTIFICATION_MODAL = 'stream-notification-modal';
export const WARNING_MODAL = 'stream-warning-modal';

export const UNAVAILABLE_MODAL = 'stream-unavailable-modal';

export const DEPOSIT_MODAL = 'stream-deposit-modal';
export const ACCESS_MODAL = 'stream-access-modal';

export const STOP_MESSAGE_TYPE = 'stop-copy-stake';

const DONT_SHOW_AGAIN_KEY = 'stream-dont-show-again';
const LOCAL_STATE_EXPIRE = 24 * 60 * 60 * 1000; // 1 day
const DATE_MAP = new Map<string, boolean>();

export const INTERVAL_DURATION = 1000 * 5; // 1 or 5 sec
export const ACTIVITY_TIMEOUT = 1000 * 60 * 5; // 5 minutes

export const WARNING_ENABLED = !isDemo;

const removeWarningState = (): void => removeLocalStorageData(DONT_SHOW_AGAIN_KEY);
export const getWarningState = (): boolean => {
  const localValue = getLocalStorageData(DONT_SHOW_AGAIN_KEY);
  const expire = isNaN(+localValue) ? 0 : +localValue;

  if (expire <= Date.now()) {
    removeWarningState();

    return false;
  }

  return true;
};
export const setWarningState = (value: boolean): void =>
  value ? setLocalStorageSimpleData(DONT_SHOW_AGAIN_KEY, Date.now() + LOCAL_STATE_EXPIRE) : removeWarningState();

export const getDefaultSubscriptionData = (): Record<EActionOwner, TStreamGameAction[]> => ({
  [EActionOwner.player]: [],
  [EActionOwner.follower]: []
});

export const defaultSubscription = (): TStreamSubscription => ({
  state: EStreamSubscriptionState.CLOSED,
  betAmount: 0,
  createdAt: null,
  deletedAt: null,
  id: null,
  player: null,
  percentage: 1,
  spinRemaining: 0,
  subscriber: null,
  changeBetRule: true,
  changeGameRule: false,
  playerWalletHash: ''
});

const checkDateDiff = (createdAt: string, timeout = ACTIVITY_TIMEOUT): number => {
  const date = createdAt.slice(0, createdAt.lastIndexOf('.'));
  const currentDate = DateTime.now().setZone('UTC').toMillis();
  const lastDate = DateTime.fromISO(date, { zone: 'UTC' }).plus({ milliseconds: timeout }).toMillis();

  return lastDate - currentDate;
};

export const checkActivityState = (record: { createdAt?: string } = {}, timeout = ACTIVITY_TIMEOUT): boolean => {
  if (isDemo) return true;

  try {
    const { createdAt } = record;

    if (!createdAt) return false;

    if (!DATE_MAP.has(createdAt)) {
      DATE_MAP.clear();
      const diff = checkDateDiff(createdAt, timeout);
      DATE_MAP.set(createdAt, diff >= 0);
      setTimeout(() => DATE_MAP.delete(createdAt), diff);
    }

    return DATE_MAP.get(createdAt);
  } catch (e) {
    return false;
  }
};

export const checkIsOffline = (): boolean => navigator && 'onLine' in navigator && !navigator?.onLine;

export const isRollbackWin = (type: EStreamActionType): boolean => EStreamActionType.ROLLBACK_WIN === type;
export const isRollbackBet = (type: EStreamActionType): boolean => EStreamActionType.ROLLBACK_BET === type;
export const isRollback = (type: EStreamActionType): boolean => isRollbackBet(type) || isRollbackWin(type);
export const isWin = (type: EStreamActionType): boolean => EStreamActionType.WIN === type;

export const formatStreamActionType = (type: EStreamActionType, short: boolean = false): string => {
  try {
    let preparedType = type as string;

    if (short && type?.includes('_')) {
      preparedType = type.split('_')[0];
    }

    return formatTXType(preparedType || type);
  } catch (e) {
    return formatTXType(type);
  }
};

export const makeAmount = (amount: number | string, type?: EStreamActionType): string => {
  const numAmount = +amount;

  if (!numAmount || isNaN(numAmount)) return '0';

  const prefix = isRollbackWin(type) ? '-' : '';

  if (numAmount < 0.0001) return `< ${prefix}0.0001`;

  const maxZeros = Math.max(5 - numAmount.toFixed(0).length, 0);

  const format = '0'.repeat(maxZeros);

  return prefix + numeralSpaces(numAmount, `0,0.[${format}]`);
};

export const LOBBY_FILTERS_KEY = 'lobby-filters';

export const LOBBY_SECTIONS = Object.values(ELobbySection);

export const lobbyFilter = {
  get: () => getLocalStorageData(LOBBY_FILTERS_KEY) || {},
  clear: () => {
    const data = getLocalStorageData(LOBBY_FILTERS_KEY) || {};
    removeLocalStorageData(LOBBY_FILTERS_KEY);

    lobbyFilter.watchers.forEach((cb) => cb({}, data));
  },
  update: (obj = {}, force = false) => {
    const data = getLocalStorageData(LOBBY_FILTERS_KEY) || {};
    const newData = force ? { ...obj } : { ...data, ...obj };

    setLocalStorageData(LOBBY_FILTERS_KEY, newData);

    lobbyFilter.watchers.forEach((cb) => cb(newData, data));
  },
  watchers: [],
  addListener: (listener: any): void => {
    if (typeof listener !== 'function') return;

    lobbyFilter.watchers.push(listener);
  }
};

export const getDefaultFilters = () => ({
  [ELobbyFilterType.LANGUAGES]: [],
  [ELobbyFilterType.GAMES]: [],
  [ELobbyFilterType.PROVIDERS]: []
});

export const USER_MAP = new Map();

const PLACEHOLDER_PATH = '/static/images/stream/placeholders/';
const IMAGE_PLACEHOLDERS = ['space.jpeg', 'fish.jpeg', 'egypt.jpeg'].map((img) => `${PLACEHOLDER_PATH}${img}`);
const IMAGE_PLACEHOLDERS_TOTAL = IMAGE_PLACEHOLDERS.length;

export const getRandomImage = (): string => {
  const idx = Math.floor(Math.random() * IMAGE_PLACEHOLDERS_TOTAL);

  return IMAGE_PLACEHOLDERS[idx];
};

const fillLobbyRecord = (rec: ILobbyRecord & { gameId?: string; gameProvider?: string }): ILobbyRecord => {
  if (!USER_MAP.has(rec.userId)) {
    USER_MAP.set(rec.userId, {
      nickname: rec.nickname,
      image: getRandomImage()
    });
  }

  const savedItem = USER_MAP.get(rec.userId);

  return {
    ...rec,
    ...savedItem,
    game: rec.game || rec.gameId,
    provider: rec.provider || rec.gameProvider,
    streamer: !!rec.streamer,
    image: rec.image || savedItem.image
  };
};
const fillLobbySections = (all: TLobbyResponsePayload, section: ELobbySection) => {
  const records = (all[section]?.records || []).map(fillLobbyRecord).sort((a, b) => +b.streamer - +a.streamer);

  return { ...all, [section]: { ...all[section], records } };
};

export const lobbyDataMiddleware = ({ data }: { data: TLobbyResponsePayload }): TLobbyResponsePayload =>
  LOBBY_SECTIONS.reduce(fillLobbySections, data as TLobbyResponsePayload);

const checkRecordProperty = (record: ILobbyRecord, filters: TLobbyRequestParams, key: keyof ILobbyRecord): boolean => {
  return filters?.[key]?.length ? filters[key].includes(record[key]) : true;
};

export const applyLobbyFilter = (params: TLobbyRequestParams) =>
  memoize(
    (record: ILobbyRecord): boolean => {
      const isStreamer = !!record?.streamer;

      const video = !params.stream || isStreamer;
      const language = !isStreamer || checkRecordProperty(record, params, 'language');
      const other = ['game', 'provider'].map((key: keyof ILobbyRecord) => checkRecordProperty(record, params, key));

      return [video, language, ...other].every(Boolean);
    },
    (record) => JSON.stringify([record.userId, params])
  );

export const applyLobbySort = (records: ILobbyRecord[] = [], sort: ELobbySortType): ILobbyRecord[] => {
  if (typeof sort === 'string' && Object.values(ELobbySortType).includes(sort)) {
    return orderBy<ILobbyRecord>(records, [(user) => user.nickname.toLowerCase()], [sort]);
  }

  return records;
};

const sliceRecords = (records: ILobbyRecord[], params: TLobbyRequestParams): TLobbyResponseItem => {
  const { size = 15, page = 0 } = params;
  const offsetFrom = size * page;
  const offsetTo = offsetFrom + size;

  return {
    records: records.slice(offsetFrom, offsetTo),
    hasNext: offsetTo < records.length,
    total: records.length
  };
};
const updateLobbyData =
  (params: TLobbyRequestParams) =>
  (all: TLobbyResponsePayload, key: ELobbySection): TLobbyResponsePayload => {
    const filteredRecords = (all[key]?.records || []).filter(applyLobbyFilter(params));
    const sortedRecords = applyLobbySort(filteredRecords, params.sort);

    return {
      ...all,
      [key]: sliceRecords(sortedRecords, params)
    };
  };

export const applyLobbyParams =
  (params: TLobbyRequestParams) =>
  (data: TLobbyResponsePayload): TLobbyResponsePayload =>
    LOBBY_SECTIONS.reduce(updateLobbyData(params), data as TLobbyResponsePayload);

export const SCHEDULE_SECTION = 'schedule';

export const isUserWallet: TCopyStakeValidator = (v) =>
  typeof v?.id === 'string' && new RegExp(/^[A-Za-z0-9]{29,34}$/).test(v?.id);
export const isLobbySection: TCopyStakeValidator = (v) =>
  typeof v?.type === 'string' && [SCHEDULE_SECTION, ...LOBBY_SECTIONS].includes(v.type);
export const isBroadcastExpired = (): boolean => {
  const url = new URL(location.href);
  const isExpired = url.searchParams.get(EXPIRED_KEY) === 'true';

  if (isExpired) {
    url.searchParams.delete(EXPIRED_KEY);
    window.history.pushState({}, document.title, url.toString());
  }

  return isExpired;
};

export const copyStakeGuard =
  (validator: TCopyStakeValidator): NavigationGuard =>
  (to, _, next) => {
    if (!validator(to.params)) {
      return next({ path: '/', query: { ...to.query } });
    }

    return next();
  };

// const AUDIO_CTX = new (window.AudioContext || window?.webkitAudioContext)();
// let BET_PLAY = false;
// let AUDIO_CTX_MUTE = false;
//
// const masterVolume = AUDIO_CTX.createGain();
// masterVolume.gain.setValueAtTime(0.05, AUDIO_CTX.currentTime);
// masterVolume.connect(AUDIO_CTX.destination);
//
// export class StreamSoundController {
//   static playNote(freq: number = 1000, dur: number = 1, type: 'sine' | 'square' = 'square'): Promise<any> {
//     return new Promise((res): void => {
//       const oscillator = AUDIO_CTX.createOscillator();
//       oscillator.type = type;
//       oscillator.frequency.setValueAtTime(freq, AUDIO_CTX.currentTime); // value in hertz
//       oscillator.connect(masterVolume);
//       oscillator.start();
//       oscillator.stop(AUDIO_CTX.currentTime + dur);
//       oscillator.onended = res;
//     });
//   }
//
//   static playWin(amount: number, init = true): void {
//     if (BET_PLAY) {
//       setTimeout(() => StreamSoundController.playWin(amount, init), 100);
//
//       return;
//     }
//
//     const currAmount = init ? amount * 5 : amount;
//     const clampedAm = currAmount > 20 ? 20 : currAmount;
//     const nextAmount = currAmount - 1;
//
//     StreamSoundController.playNote(400 + 100 * (20 - clampedAm), 0.05, 'sine');
//
//     if (nextAmount > 0) {
//       setTimeout(() => StreamSoundController.playWin(nextAmount, false), 70);
//     }
//   }
//
//   static playBet(reels = 3): void {
//     BET_PLAY = true;
//     const nextReel = reels - 1;
//
//     StreamSoundController.playNote(160 - (30 - reels * 10), 0.1);
//
//     if (nextReel > 0) {
//       setTimeout(() => StreamSoundController.playBet(nextReel), 100);
//     } else {
//       BET_PLAY = false;
//     }
//   }
//
//   static addEffect(action: TStreamGameAction): void {
//     if (AUDIO_CTX_MUTE) return;
//
//     const { amount, actionType } = action;
//
//     isWin(actionType) ? StreamSoundController.playWin(amount) : StreamSoundController.playBet();
//   }
//
//   static toggle(): void {
//     AUDIO_CTX_MUTE = !AUDIO_CTX_MUTE;
//   }
// }
