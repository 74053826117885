
































import { PropType } from 'vue';
import ArrowDown from '@/assets/images/arrow_down.svg?inline';
import ClickOutside from 'vue-click-outside';

type BaseOption = {
  value: string;
  label: string;
};

type Option = BaseOption & Record<string, any>;
export default {
  name: 'BaseDropdown',
  directives: {
    ClickOutside
  },
  components: {
    ArrowDown
  },
  props: {
    options: {
      type: Array as PropType<Option[]>,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    lined: {
      type: Boolean,
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      isOpen: false
    };
  },

  computed: {
    currentOptions(): Option[] {
      return this.hideSelected ? this.options.filter(({ value }) => value !== this.value) : this.options;
    },
    currentValue(): Option {
      return Array.isArray(this.options) && this.options.find(({ value }) => value === this.value);
    },
    modifiers(): Record<string, boolean> {
      return {
        'base-dropdown--active': this.isOpen,
        'base-dropdown--lined': this.lined
      };
    }
  },

  methods: {
    toggleMenu(): void {
      if (!this.isOpen) {
        this.$emit('open');
      }

      this.isOpen = !this.isOpen;
    },
    closeMenu(): void {
      this.isOpen = false;
    },
    selectItem(value: string): void {
      this.$emit('input', value);
      this.closeMenu();
    }
  }
};
