































import { PropType } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'StreamFormControlsBlock',
  components: {
    BaseButton
  },
  props: {
    value: {
      type: Number as PropType<number>,
      default: 0
    },
    controlsVal: {
      type: Array as PropType<number[]>,
      default: (): number[] => [10, 50, 75, 100]
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    disableDecrease: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    disableIncrease: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    hidePresets: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  computed: {
    currentValue(): any {
      return Math.max(this.value, 0);
    }
  },
  methods: {
    onControlsChange(value: number): void {
      this.$emit('onControlsChange', value);
    },
    onDecrease(): void {
      this.$emit('onDecrease');
    },
    onIncrease(): void {
      this.$emit('onIncrease');
    }
  }
};
