
























import { mapState } from 'vuex';
import type { RouteItem } from '@/api/schema';

interface Route {
  title: string;
  route: string;
  parent: string;
  animate?: boolean;
}

const StreamIcon = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/assets/images/navbar/streamers_inactive.svg?inline');
const StreamIconActive = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/assets/images/navbar/streamers_active.svg?inline');

const AccountIcon = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/assets/images/navbar/account_inactive.svg?inline');
const AccountIconActive = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/assets/images/navbar/account_active.svg?inline');

export default {
  name: 'BottomNavigation',
  components: {
    StreamIcon,
    StreamIconActive,
    AccountIcon,
    AccountIconActive
  },
  computed: {
    ...mapState('layout', ['token']),

    links(): Route[] {
      const streamersRoute = {
        title: this.$t('stream.nav'),
        route: '/',
        parent: '/'
      };
      const accountRoute = {
        title: this.$t('new.bottom-navigation.routes.account'),
        route: '/account',
        parent: 'account'
      };

      return [streamersRoute, accountRoute];
    },
    totalLinks(): number {
      return this.links.length;
    },
    mainRouteName(): any {
      const path = this.$route?.matched[0]?.path;

      return path === '' ? '/' : path;
    }
  },
  methods: {
    isLinkDisabled(el: Route): boolean {
      if (this.token) return false;

      return el.route === '/';
    },
    navLinkAction(route: string): void {
      if (route === '/account') {
        window.gtag('event', 'Account', {
          Category: 'Navigation',
          Action: 'Click',
          Label: 'Account'
        });

        window.dataLayer.push({
          event: 'navigation_account',
          eventCategory: 'widget_navigation',
          eventAction: 'click',
          eventLabel: 'account'
        });
      }
    },

    getIcon({ route }: RouteItem): any {
      const isActive = route === this.mainRouteName;

      const iconSet = Object.freeze({
        '/': 'StreamIcon',
        '/account': 'AccountIcon'
      });

      return `${iconSet[route]}${isActive ? 'Active' : ''}`;
    }
  }
};
