import { isDemo } from '@/api/helpers/urlParams';

export const ANONYMOUS_SESSION_TIMEOUT = 300;

export const ANONYMOUS_SESSION_STORAGE_KEY = 'anonymous-session-total-watched-seconds';

export const getAnonymousSessionProgress = (): number => {
  const totalWatchedSeconds = Number(localStorage.getItem(ANONYMOUS_SESSION_STORAGE_KEY));
  return isNaN(totalWatchedSeconds) ? 0 : totalWatchedSeconds;
};

export const setAnonymousSessionProgress = (totalWatchedSeconds: number): void => {
  localStorage.setItem(ANONYMOUS_SESSION_STORAGE_KEY, totalWatchedSeconds.toString());
};

export const isAnonymousSessionExpired = (totalWatchedSeconds: number = getAnonymousSessionProgress()): boolean => {
  if (isDemo) return false;

  return totalWatchedSeconds >= ANONYMOUS_SESSION_TIMEOUT;
};
