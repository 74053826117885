export interface UserInfo {
  listed: boolean;
  role: string;
  operatorId: number;
  walletHash: string;
  language: string;
  operatorName: string;
  operatorToken: string;
  operatorTokenTicker: string;
  operatorIsNew: boolean;
  emailNotificationEnabled: boolean;
  pushNotificationEnabled: boolean;
  notificationEmail: string;
  use2fa: boolean;
  proWidget: boolean;
  notificationEmailVerified: boolean;
  lastLogin: string;
  referralProgramEnabled: boolean;
  referralCode: string | null;
  referralId: number | null;
  subInvitee: boolean;
  operatorIsTest: boolean;
  stakingCount: number | null;
  operatorHomePageURL: string | null;
  nickname: string;
}

export interface OperatorBalance {
  currency: string;
  balance: number;
}

export interface LimitObject {
  limit: number;
  message?: string;
}

export type LimitParam = LimitObject | LimitObject[];

export interface TransactionItem {
  currency: string;
  createdAt: string;
  status: TransactionItemStatus;
  amount: number;
  type: TransactionItemType;
  hashId?: string;
}

export type TransactionItemType =
  | 'DEPOSIT'
  | 'WITHDRAW'
  | 'PROMO REWARD'
  | 'REFERRAL REGISTRATION REWARD'
  | 'DEPOSIT FOR LIQUIDITY'
  | 'WITHDRAW FROM LIQUIDITY'
  | 'DAILY CASHBACK REWARD'
  | 'WEEKLY CASHBACK REWARD';

export type TransactionItemStatus = 'CANCELED' | 'COMPLETED' | 'DECLINED' | 'FAILED' | 'PROCESSING' | 'APPROVED';

export interface TransactionsHistoryResponce {
  hasNext: boolean;
  transactions: TransactionItem[];
}

export interface LeaderboardH2EItem {
  position: number | null;
  wallet: string | null;
  percentage: number | null;
  stake: number | null;
  earn: number;
  nftUrl: null;
}

export interface WidgetThemeResponse {
  value: string;
  colors: Array<{
    type: string;
    color: string;
  }>;
}

export interface WidgetTheme {
  value?: string;
  colors?: { [key: string]: string };
}

export type PaymentMethodType =
  | 'OPERATOR_DEPOSIT'
  | 'OPERATOR_WITHDRAW'
  | 'CRYPTO_DEPOSIT_ETH'
  | 'CRYPTO_DEPOSIT_BSC'
  | 'CRYPTO_WITHDRAW_ETH'
  | 'CRYPTO_WITHDRAW_BSC';

export interface PaymentMethodResponse {
  active: boolean;
  id: number;
  maximumTransactionAmount: number;
  minimumTransactionAmount: number;
  type: PaymentMethodType;
  userId: number;
  fee: number;
  preSale: boolean;
  dynamicLimit: boolean;
  feeIsActive: boolean;
}

export interface RouteItem {
  title: string;
  route: string;
  parent?: string;
  class?: string | Record<string, boolean>;
}

export enum EStreamEventType {
  CLOSE = 'CLOSE_SUBSCRIPTION',
  UPDATE = 'UPDATE_SUBSCRIPTION',
  PLAYER_ACTION = 'PLAYER_ACTION',
  TIMEOUT = 'TIMEOUT_REACHED',
  STREAMER_STATUS_CHANGE = 'STREAMER_STATUS_CHANGE',
  WS_RECONNECT = 'WS_RECONNECT'
}

export enum EStreamActionType {
  BET = 'BET',
  WIN = 'WIN',
  ROLLBACK_BET = 'ROLLBACK_BET',
  ROLLBACK_WIN = 'ROLLBACK_WIN'
}

export type TStreamGameAction = {
  amount: number;
  amountUsd: number;
  currency: string;
  dateTime: string;
  gameName: string;
  gameType: string;
  actionType: EStreamActionType;
  uuid: string;
};

export type TStreamBalance = {
  balance: number;
  spinRemaining: number;
};

export type TStreamUpdateMessage = {
  balanceChange: TStreamBalance;
  playerAction: TStreamGameAction;
  followerAction: TStreamGameAction;
};

export type TStreamWalletBalance = {
  balance: number;
  currency: string;
  hashId: string;
  walletType: string;
};

export type TStreamSettings = {
  maxBetVolume: number;
  maxBetVolumeActive: boolean;
  showOnlyStreamers: boolean;
  streamUrl: string;
};

export enum EStreamCloseReason {
  USER_LOST_CONNECTION = 'USER_LOST_CONNECTION',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INSUFFICIENT_FUNDS_START = 'INSUFFICIENT_FUNDS_START',
  INSUFFICIENT_SPINS = 'INSUFFICIENT_SPINS',
  CHANGED_BET_RULE = 'CHANGED_BET_RULE',
  STREAMER_OFFLINE = 'STREAMER_OFFLINE',
  OFF_BET_INACTIVE = 'OFF_BET_INACTIVE',
  CHANGED_GAME_TYPE = 'CHANGED_GAME_TYPE',
  UNSUPPORTED_GAME_TYPE = 'UNSUPPORTED_GAME_TYPE',
  TURN_OFF = 'TURN_OFF',
  SESSION_EXPIRED = 'SESSION_EXPIRED'
}

export type TStreamCloseMessage = {
  reason: EStreamCloseReason;
  subscriptionId: number;
};

export type TStreamBusCloseEvent = {
  type: EStreamEventType.CLOSE;
  data: TStreamCloseMessage;
};

export type TStreamBusUpdateEvent = {
  type: EStreamEventType.UPDATE;
  data: TStreamUpdateMessage;
};

export type TStreamBusTimeoutEvent = {
  type: EStreamEventType.TIMEOUT;
};

export type TStreamMessage = TStreamBusCloseEvent | TStreamBusUpdateEvent | TStreamBusTimeoutEvent;

export enum EStreamStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  BROADCAST = 'BROADCAST',
  STARTING = 'STARTING'
}

export type TStreamer = {
  channelId: string;
  createdAt: string;
  followersCount: number;
  id: number;
  isActive: boolean;
  nickname: string;
  operatorId: number;
  playerId: number;
  status: EStreamStatus;
  playerWalletHash?: string;
  avatar?: string;
  game?: string;
};

export type TStreamBroadcastStatus = {
  status: EStreamStatus;
};

export enum EStreamSubscriptionState {
  ACTIVE = 'ACTIVE',
  PAUSE = 'PAUSE',
  TERMINATION = 'TERMINATION',
  CLOSED = 'CLOSED'
}

export type TStreamSubscription = {
  state: EStreamSubscriptionState;
  betAmount: number;
  createdAt: string;
  deletedAt: string;
  id: number;
  percentage: number;
  player: number;
  playerWalletHash: string;
  spinRemaining: number;
  subscriber: number;
  changeBetRule: boolean;
  changeGameRule: boolean;
};

export type TStreamSubscriptionPayload = {
  isChangeBetRule: boolean;
  isChangeGameRule?: boolean;
  percentage: number;
  spinRemaining: number;
};

export enum EStreamWalletDirection {
  STREAMER = 'deposit',
  GENERAL = 'withdraw',
  HISTORY = 'history'
}

export type TStreamDepositPayload = {
  amount: number;
};

export type TStreamWithdrawPayload = {
  tokenAmount: number;
  code: string;
};

// CopyStake Lobby

export interface ILobbyUser {
  userId?: number;
  wallet: string;
  walletHash?: string;
  nickname: string;
}

export interface ILobbyRecord extends ILobbyUser {
  image?: string;
  language?: string;
  game?: number;
  provider?: string;
  rtp?: number;
  streamer?: boolean;
  channelId?: string;
  gameName?: string;
  userId?: number;
}

export interface ILobbyRecordV2 extends ILobbyUser {
  userId?: number;
  image?: string;
  language?: string;
  gameId?: number;
  gameName?: number;
  gameProvider?: string;
  rtp?: number;
  streamer?: boolean;
  channelId?: string;
}

export enum ELobbySection {
  LIVE = 'live',
  WINNING = 'winning',
  LOSING = 'losing'
}

export enum ELobbySortType {
  ASC = 'asc',
  DESC = 'desc'
}

export type TLobbyRequestParams = {
  language?: string[];
  provider?: string[];
  game?: string[];
  stream: boolean;
  type?: ELobbySection;
  sort?: ELobbySortType;
  size: number;
  page?: number;
};

export type TLobbyResponseItem = {
  records: ILobbyRecord[];
  total: number;
  hasNext: boolean;
};

export type TLobbyResponsePayload = Record<ELobbySection, TLobbyResponseItem>;

export type TLobbyGameRecord = {
  id: number;
  name: string;
};

export enum ELobbyFilterType {
  GAMES = 'games',
  PROVIDERS = 'providers',
  LANGUAGES = 'languages'
}

export type TLobbyFilterResponse = {
  [ELobbyFilterType.LANGUAGES]: string[];
  [ELobbyFilterType.PROVIDERS]: string[];
  [ELobbyFilterType.GAMES]: TLobbyGameRecord[];
};

export type TRoomResponse = {
  accessToken: string;
};
