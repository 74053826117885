import numeral from 'numeral';

const formatNumber = (value: number | string, format: string): string => {
  numeral.nullFormat('0');
  const val = Math.abs(+value) < 0.000001 ? 0 : value;

  return isNaN(+val) ? val : numeral(val).format(format).replace(/,/g, ' ');
};

export default formatNumber;
