









import type { PropType } from 'vue';

export default {
  name: 'BaseButton',

  props: {
    type: {
      type: String as PropType<string>,
      default: (): string => 'button'
    },
    large: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    buttonType: {
      type: String as PropType<string>,
      default: (): string => null
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },

  methods: {
    handleClick(e: Event): void {
      if (this.disabled || this.buttonType === 'disabled') {
        e?.preventDefault();
        return;
      }

      this.$emit('click');
    }
  }
};
